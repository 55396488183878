<template>
    <input v-if="dataLookupControl.textInput" :ref="setTarget" :readonly="dataLookupControl.readonly" v-bind="$attrs" v-model="wrappedModelValue">
    <slot v-else name="target" :target="setTarget" :open="() => showDialog = true" >
        <input v-model="wrappedModelValue" :readonly="dataLookupControl.readonly" v-bind="$attrs" :ref="setTarget" type="text">
    </slot>
    <ODialog v-model:show="showDialog" ref="dialog" disableFade disableFocus :target="targetEl" resetPositionOnOpen
        headerClass="o365-lookup-dialog-header"
        @show="onBeforeOpen" @hide="onBeforeClose" @shown="onOpen" :minWidth="250"
        :style="{
            height: dataLookupControl.height+'px',
            width: dropdownWidth
        }">
        <template #header>
            <slot name="header"></slot>
            <button v-if="!noCloseButton" class="btn-close ms-auto" @click="() => showDialog = false"></button>
        </template>
        <div class="o365-dialog-body d-flex" style="padding: 0px!important;" ref="containerEl">
            <slot name="listContainer">
                <slot></slot>
            </slot>
        </div>
    </ODialog>
</template>


<script setup>
import { ODialog } from 'o365-ui-components';
import { addEventListener } from 'o365-vue-utils';
import { detectOverflow } from 'popper';
import { ref, watch, computed, onMounted, inject } from 'vue';

const dialog = ref(null);

const props = defineProps({
    dataLookupControl: {
        required: true
    },
    modelValue: {},
    value: {}
});

const isEditorInGrid = inject('is-in-grid-cell', false);
const showDialog = ref(false);
const targetEl = ref();
const containerEl = ref();
function setTarget(el) {
    targetEl.value = el;
}

const lookupHeight = ref(props.dataLookupControl.height);
//props.dataLookupControl.useLeftSizers = ref(false);
props.dataLookupControl.useLeftSizers = false;

const emit = defineEmits(['onbeforeopen', 'onbeforeclose', 'update:modelValue', 'onopen', 'beforeopen', 'beforeclose', 'open']);
const wrappedModelValue = computed({
    get: () => props.modelValue ? props.modelValue : props.value,
    set: (value) => emit('update:modelValue', value),
});

const popperOptions = [
    {
        name: 'sizeLookupWithOverflow',
        enabled: true,
        phase: 'main',
        requiresIfExists: ['offset'],
        fn(ctx) {
            const overflow = detectOverflow(ctx.state, {});
            if (overflow.bottom > 0) {
                const newHeight = lookupHeight.value - overflow.bottom;
                lookupHeight.value = newHeight > 200 ? newHeight : 200;
            } else if (overflow.top > 0) {
                const newHeight = lookupHeight.value - overflow.top;
                lookupHeight.value = newHeight > 200 ? newHeight : 200;
            } else {
                lookupHeight.value = props.dataLookupControl.height;
            }
        }
    }
];

const dropdownWidth = computed(() => {
    const scrollbar = 20;

    return props.dataLookupControl.dataGridRef?.dataColumns.value?.centerWidth + props.dataLookupControl.dataGridRef?.dataColumns.value?.leftPinnedWidth + scrollbar + 10 + 'px';
});
const windowSize = ref(window.innerWidth);
addEventListener(window, 'resize', () => {
    windowSize.value = window.innerWidth
})
const maxWidthValue = computed(() => {
    return props.dataLookupControl.maxWidth ?? windowSize.value;
})

watch(dropdownWidth, () => {
    window.requestAnimationFrame(() => {
        props.dataLookupControl.dataGridRef?.setViewPortWidth();
    });
});

// watch(() => dialog.value?.popperInstance?.state.placement, (placement) => {
    // if (typeof placement === 'string' && placement.includes('end')) {
        // props.dataLookupControl.useLeftSizers = true;
    // } else {
        // props.dataLookupControl.useLeftSizers = false;
    // }
// });

if (props.dataLookupControl && props.dataLookupControl.reloadOnWhereClauseChange) {
    watch(() => props.dataLookupControl.whereClause, (newClause) => {
        console.log(newClause);
        if (showDialog.value) {
            props.dataLookupControl.whereClause = newClause;
            props.dataLookupControl.load(true);
        }
    });
}

function onBeforeOpen() {
    if (props.multiselect && props.clearMultiselect) {
        props.dataLookupControl.dataObject.data.filter(x => x.isSelected).forEach(item => {
            item.isSelected = false;
        });
    }

    emit('onbeforeopen');
    emit('beforeopen');

    props.dataLookupControl.load();
}

function onBeforeClose() {
    props.dataLookupControl.navigation.removeHandler();
    if (props.dataLookupControl.focusInputAfterClose) {
        targetEl.value?.focus();
    }
    emit('onbeforeclose');
    emit('beforeclose');

};

function onOpen() {
    props.dataLookupControl.dataGridRef?.setViewPortWidth();
    props.dataLookupControl.navigation.addHandler(containerEl.value);
    emit('onopen');
    emit('open');
}

onMounted(() => {
    props.dataLookupControl.dropdown = dialog.value;
    if (!props.dataLookupControl.suppressClickEvent) {
        
        targetEl.value.onclick = () => {
            showDialog.value = true;
        }
    }

    if (props.dataLookupControl.openonfocus) {
        targetEl.value.onfocus = () => {
            showDialog.value = true;
        }
    }

    if (!props.dataLookupControl.suppressKeyboardEvents) {
        // TODO: Add back later on
        if (containerEl.value == null) { return; }
        containerEl.value.addEventListener('keydown', (e) => {
            if (e.key === 'F4') {
                showDialog.value = !showDialog.value;
            }
        });
    }

    if (targetEl.value && targetEl.value.tagName.toLowerCase() === "input") {
        // Add chevron icon to input elements
        targetEl.value.classList.add("lookup-icon", "text-truncate");
    }
});

defineExpose({ dropdown: dialog });

</script>

<style>
.o365-lookup-dialog-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    padding: .25rem;
    background-color: var(--bs-light-bg-subtle);
}
</style>